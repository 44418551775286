@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.dino-footer {
  padding: 96px 120px;

  &-content {

  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 120px;
  background: #F3F5F7;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 120px;
  background: url("assets/Dashboard.png");
  background-size: cover;
  height: 90.7vh;
}

.base-container {
  background: #F3F5F7;
}

@media screen and (max-width: 700px) {
  .dino-footer {
    padding: 15px;
  }
  .login-container {
    padding: 15px;
  }
}


.login-card {
  background: #fff;
  padding: 48px;
  border-radius: 4px;
}

.menu-button {
  border-radius: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;

  &__icon {
    color: #7D99BA !important;
  }

  &__text {
    color: #062B45 !important;
    text-transform: capitalize !important;
  }
}

.menu-item {
  border-radius: 16px;
}

.main {
  padding: 64px;
  //padding-top: 90px;
  overflow-x: hidden;
  background: #F8F9FC;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .main {
    padding: 10px;
    padding-top: 20px;
  }
}

.reservations-main {
  padding: 64px;
  padding-top: 120px;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .reservations-main {
    padding: 10px;
    padding-top: 20px;
  }
}

.web-main {
  padding: 64px;
}

.dashboard-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
}

.white-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

.gradient-card {
  padding: 32px;
  border-radius: 16px;
  background: linear-gradient(#3F4D5D, #5E738C);
  color: white;
  margin-bottom: 20px;
}

.white-card-sp {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .white-card-sp {
    padding: 2px;
  }
  .table-heading {
    font-size: 70% !important;
  }

}

.reservation-name {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.reservation-accepted {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #5EB885;
}

.reservation-waiting {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ED8802;
}

.active-radio {
  background: #EFF8F3;
  border: 1px solid #9ED4B6;
  border-radius: 16px;
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.not-active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #F3F5F7 solid 3px
}

.reservation-detail-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.bank-card {
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid #2a3746;
  background: #606e8c;
}

$green: #adc9a6;
$red: #ff7073;
$blue: #9abdd6;
$yellow: #edf67d;
$purple: #9c00dc;
$pink: #ff00df;
$changed: #ff0000;

.super-app-theme {
  &--changed {
    background-image: repeating-linear-gradient(
                    -45deg,
                    $changed 0 20px,
                    darken($changed, 20%) 20px 40px
    ) !important;

    &:hover {
      background-image: repeating-linear-gradient(
                      -45deg,
                      darken($changed, 10%) 0 20px,
                      darken($changed, 30%) 20px 40px
      ) !important;
    }
  }
  &--green {
    background: $green !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $green 0 20px,
                      darken($green, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($green, 10%) 0 20px,
                        darken($green, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($green, 20%) !important;
    }
  }

  &--red {
    background: $red !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $red 0 20px,
                      darken($red, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($red, 10%) 0 20px,
                        darken($red, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($red, 20%) !important;
    }
  }

  &--blue {
    background: $blue !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $blue 0 20px,
                      darken($blue, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($blue, 10%) 0 20px,
                        darken($blue, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($blue, 20%) !important;
    }
  }

  &--yellow {
    background: $yellow !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $yellow 0 20px,
                      darken($yellow, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($yellow, 10%) 0 20px,
                        darken($yellow, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($yellow, 20%) !important;
    }
  }

  &--purple {
    background: $purple !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $purple 0 20px,
                      darken($purple, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($purple, 10%) 0 20px,
                        darken($purple, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($purple, 20%) !important;
    }
  }

  &--pink {
    background: $pink !important;

    &--changed {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $pink 0 20px,
                      darken($pink, 20%) 20px 40px
      ) !important;

      &:hover {
        background-image: repeating-linear-gradient(
                        -45deg,
                        darken($pink, 10%) 0 20px,
                        darken($pink, 30%) 20px 40px
        ) !important;
      }
    }

    &:hover {
      background: darken($pink, 20%) !important;
    }
  }
}


.negative {
  background: #ff8586;
}

.positive {
  background: #69acff;
}